@import "import/core.scss";
@import "import/utils.scss";
@import "import/measurements.scss";
@import "import/settings.scss";

:global(.ant-input-lg) {
    height: 40px;
    padding: 0px 0px !important;
    font-size: 15px;
    line-height: 2.5 !important;
}
