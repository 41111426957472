@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'assets/styles/Antd/antd.cleanui';
@import 'assets/styles/CleanUI/cleanui';
@import 'assets/styles/Bootstrap/bootstrap.cleanui';
@import 'assets/styles/Chartist/chartist.cleanui';
@import 'assets/styles/Nprogress/nprogress.cleanui';
@import 'assets/styles/Rcdrawer/rcdrawer.cleanui';
@import '~c3/c3.min.css';
@import '~react-datasheet/lib/react-datasheet.css';

:global(.utils__title) {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-size: rem(16);

  span {
    padding-left: rem(5);
  }
}

.data-editor {
  border: 0px !important;
  height: 19px !important;
  min-width: 50px !important;
}

.value-viewer {
  min-width: 50px !important;
}
